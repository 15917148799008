// noinspection DuplicatedCode

import PageHeader from "../../../components/layout/PageHeader";
import PageContent from "../../../components/layout/PageContent";
import {DataTable, DataTableColumn, DataTableSortStatus, useDataTableColumns} from "mantine-datatable";
import {useContext, useEffect, useState} from "react";
import Page from "../../../models/api/Page";
import {
    Action,
    CompositeReport,
    CompositeReportApi,
    Validation
} from "../../../api/composite/report/CompositeReportApi";
import PaginationFooter from "../../../components/pagination/PaginationFooter";
import {showNotification} from "@mantine/notifications";
import {User} from "../../../api/user/UserApi";
import DateTimeView from "../../../components/date/DateTimeView";
import {ActionIcon, Anchor, Menu} from "@mantine/core";
import {modals} from "@mantine/modals";
import CompositeReportFilterDialog from "./CompositeReportFilterDialog";
import {useDisclosure} from "@mantine/hooks";
import GenerateForAllDevicesDialog from "./GenerateForAllDevicesDialog";
import {useContextMenu} from "mantine-contextmenu";
import {useNavigate} from "react-router-dom";
import {
    IconCheck,
    IconDotsVertical,
    IconEdit,
    IconEye,
    IconReport,
    IconSend,
    IconTrash,
    IconX
} from "@tabler/icons-react";
import Cell, {CellHighlight} from "../../../components/table/Cell";
import {FtpApi} from "../../../api/ftp/FtpApi";
import EditSimpleDataDialog from "./details/EditSimpleDataDialog";
import SendStatusCell from "../../../components/send/report/SendStatusCell";
import ValidationCell from "../../../api/composite/report/validation/ValidationCell";
import CompositeReportFilter from "./CompositeReportFilter";
import NiceCommentsDialog from "../../../components/comments/dialog/nice/NiceCommentsDialog";
import CompositeReportContext from "../../../contexts/CompositeReportContext";

const key = 'CompositeReport-table-key';

function UserCell({user}: {user?: User | null}) {
    return (
        <>
            { user ? user.username : '-' }
        </>
    );
}

type CompositeReportPageMenuAttrs = {
    deleteSelected: () => void;
    sendSelected: () => void;
    approveSelected: () => void;
    doFilter: () => void;
    generateForAllDevices: () => void;
    selectApproved: () => void;
    archiveSelected: () => void;
}

function CompositeReportPageMenu(attrs: CompositeReportPageMenuAttrs) {
    return (
        <Menu width={250}>
            <Menu.Target>
                <ActionIcon variant="transparent">
                    <IconDotsVertical/>
                </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
                <Menu.Label>Filtrowanie</Menu.Label>
                <Menu.Item onClick={attrs.doFilter}>Filtrowanie wyników</Menu.Item>

                <Menu.Label>Generowanie</Menu.Label>
                <Menu.Item onClick={attrs.generateForAllDevices}>Generuj dla wszystkich urządzeń</Menu.Item>
                <Menu.Divider></Menu.Divider>

                <Menu.Label>Zaznaczenie wielokrotne</Menu.Label>
                <Menu.Item onClick={attrs.approveSelected}>Zatwierdź zaznaczone</Menu.Item>
                <Menu.Item onClick={attrs.sendSelected}>Wyślij do klientów</Menu.Item>
                <Menu.Item onClick={attrs.archiveSelected}>Przenieś do archiwum</Menu.Item>
                <Menu.Item onClick={attrs.deleteSelected} color="red">Usuń zaznaczone</Menu.Item>

                <Menu.Label>Zaznaczanie</Menu.Label>
                <Menu.Item onClick={attrs.selectApproved}>Zaznacz zatwierdzone</Menu.Item>
            </Menu.Dropdown>
        </Menu>
    )
}

function CsvFileCell({filePath}: {filePath: string | undefined}) {
    const download = () => {
        if (!filePath) return;
        FtpApi.doDownloadFile({filePath});
    }


    if (!filePath) {
        return (
            <Cell orOptional={undefined}/>
        )
    }

    return (
        <Anchor children="Pobierz" onClick={download} />
    );
}

function SendStatusCellValue({compositeReport}: {compositeReport: CompositeReport}) {
    const cr = compositeReport as any;

    if (!("sendReports" in cr)) {
        return <SendStatusCell status={undefined} />
    }

    // noinspection JSUnresolvedReference
    const sendReports = (cr.sendReports as any[]);
    const sendReportsProcessed = sendReports.filter(x => x.sendStatus);

    if (sendReportsProcessed.length === 0) {
        return <SendStatusCell status={undefined} />;
    }

    const okSendReports = sendReports.filter(x => x.sendStatus === "OK");

    if (okSendReports.length > 0) {
        return <SendStatusCell status={"ok"}/>;
    }

    return <SendStatusCell skipText status={"not_approved"}/>
}

function SendReportSentAtCell({compositeReport}: {compositeReport: CompositeReport}) {
    const cr = compositeReport as any;

    if (!("sendReports" in cr)) {
        return (<Cell orOptional={undefined} />);
    }

    // noinspection JSUnresolvedReference
    const sendReports = (cr.sendReports as any[]);
    const sendReportsProcessed = sendReports.filter(x => x.processedAt);

    if (sendReportsProcessed.length === 0) {
        return (<Cell orOptional={undefined} />);
    }

    return <DateTimeView dateTime={sendReports[0].processedAt}/>
}

function PdfReportCell({compositeReport}: {compositeReport: CompositeReport}) {
    const cr = compositeReport as any;

    if (!("pdfReports" in cr)) {
        return <Cell orOptional={undefined}/>;
    }

    // noinspection JSUnresolvedReference
    const pdfReports = (cr.pdfReports as any[]).filter(x => x.filePath);

    if (pdfReports.length === 0) {
        return <Cell orOptional={undefined}/>
    }

    const {filePath} = pdfReports[0];
    return (<Anchor children="Pobierz" onClick={() => FtpApi.doDownloadFile({filePath})}/>);
}

function getDevice(compositeReport: any, key: string): string | undefined {
    const device = compositeReport.devices[0];
    if (!device) return undefined;
    return device[key];
}

function getClient(compositeReport: any, key: string): string | undefined {
    const device = compositeReport.devices[0];
    if (!device || !device['client']) return undefined;
    return device.client[key];
}

function CompositeReportPage() {
    const columns: DataTableColumn[] = [
        {
            accessor: 'id',
            title: '_id',
            toggleable: true,
            defaultToggle: false,
            sortable: true
        },
        {
            accessor: 'unit',
            title: '#',
            toggleable: false,
            sortable: true,
            draggable: true,
            render: r => <CellHighlight searchText={searchQuery.unit} text={r.unit}/>
        },
        {
            accessor: 'title',
            title: 'Tytuł',
            toggleable: true,
            sortable: true,
            draggable: true,
            render: r => <CellHighlight searchText={searchQuery.q} text={r.title}/>
        },
        {
            accessor: 'description',
            title: 'Opis',
            toggleable: true,
            sortable: true,
            draggable: true,
            render: r => <CellHighlight searchText={searchQuery.q} text={r.description}/>
        },
        {
            accessor: 'filePath',
            title: 'Plik raportu',
            toggleable: true,
            sortable: true,
            draggable: true,
            render: (r) => <CsvFileCell filePath={r.filePath as unknown as string | undefined}/>
        },
        {
            accessor: 'createdBy',
            title: 'Utworzył',
            toggleable: true,
            sortable: true,
            render: (r) => <UserCell user={r.createdBy as User}/>,
            draggable: true
        },
        {
            accessor: 'approvedBy',
            title: 'Zatwierdził',
            toggleable: true,
            sortable: true,
            render: (r) => <UserCell user={r.approvedBy as User}/>,
            draggable: true
        },
        {
            accessor: 'approvedAt',
            title: 'Zatwierdzono',
            toggleable: true,
            sortable: true,
            render: (r) => <DateTimeView dateTime={r.approvedAt}/>,
            draggable: true
        },
        {
            accessor: 'createdAt',
            title: 'Utworzono',
            toggleable: true,
            sortable: true,
            render: (r) => <DateTimeView dateTime={r.createdAt}/>,
            draggable: true
        },
        {
            accessor: 'sendReports',
            title: 'Status (wysyłka)',
            toggleable: true,
            sortable: false,
            render: (r) => <SendStatusCellValue compositeReport={r as any}/>,
            draggable: true
        },
        {
            accessor: 'sendReports.length',
            title: 'Data wysłania',
            toggleable: true,
            sortable: false,
            render: (r) => <SendReportSentAtCell compositeReport={r as any}/>,
            draggable: true
        },
        {
            accessor: 'pdfReports',
            title: "Plik PDF",
            toggleable: true,
            sortable: false,
            render: (r) => <PdfReportCell compositeReport={r as any}/>,
            draggable: true
        },
        {
            accessor: 'comments',
            title: 'Komentarze',
            toggleable: true,
            defaultToggle: false,
            render: (r) => (r.comments as string[]).length ?? 0,
            draggable: true
        },
        {
            accessor: 'validations',
            title: 'Walidacja',
            toggleable: true,
            defaultToggle: false,
            render: (r) => <ValidationCell validations={(r.validations as Validation[])}/>,
            draggable: true
        },
        {
            accessor: 'isArchived',
            title: 'Archiwum',
            toggleable: true,
            defaultToggle: false,
            render: r => r.isArchived === true ? 'Tak' : 'Nie',
            draggable: true
        },
        {
            accessor: 'deviceName',
            title: 'Nazwa (urządzenia)',
            toggleable: true,
            defaultToggle: false,
            render: r => <Cell orOptional={getDevice(r, 'name')}/>,
            draggable: true
        },
        {
            accessor: 'deviceDescription',
            title: 'Opis (urządzenia)',
            toggleable: true,
            defaultToggle: false,
            render: r => <Cell orOptional={getDevice(r, 'description')}/>,
            draggable: true
        },
        {
            accessor: 'deviceLocation',
            title: 'Lokalizacja (urządzenia)',
            toggleable: true,
            defaultToggle: false,
            render: r => <Cell orOptional={getDevice(r, 'location')}/>,
            draggable: true
        },
        {
            accessor: 'clientVisibleName',
            title: 'Klient',
            toggleable: true,
            defaultToggle: false,
            render: r => <Cell orOptional={getClient(r, 'visibleName')}/>,
            draggable: true
        },
        {
            accessor: 'clientEmailAddress',
            title: 'Adres E-mail (Klient)',
            toggleable: true,
            defaultToggle: false,
            render: r => <Cell orOptional={getClient(r, 'emailAddress')}/>,
            draggable: true
        },
        {
            accessor: 'clientPhoneNumber',
            title: 'Numer telefonu (Klient)',
            toggleable: true,
            defaultToggle: false,
            render: r => <Cell orOptional={getClient(r, 'phoneNumber')}/>,
            draggable: true
        },

        {
            accessor: 'deviceMessages',
            title: 'Komunikaty',
            toggleable: true,
            defaultToggle: false,
            render: (r: any) => <Cell orOptional={r.deviceMessages.length}/>
        }
    ];

    // const [filtersVisible, setFiltersVisible] = useState(false);
    const { sortStatus, setSortStatus, isFiltersVisible, setFiltersVisible, searchQuery, setSearchQuery } = useContext(CompositeReportContext);
    const navigate = useNavigate();
    const { showContextMenu } = useContextMenu();
    const [page, setPage] = useState<Page<CompositeReport>>();
    // const [searchQuery, setSearchQuery] = useState<SearchQuery>({ limit: 10, page: 0 });
    const [loading, setLoading] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState<CompositeReport[]>([]);
    const [isFilterDialogOpen, filterDialog] = useDisclosure();
    const [isGenerateForAllDevicesOpen, generateForAllDevicesDialog] = useDisclosure();

    const [isEditSimpleDataDialogOpen, editSimpleDataDialog] = useDisclosure();
    const [editSimpleDataSelectedReport, setEditSimpleDataSelectedReport] = useState<CompositeReport>();
    const [isCommentsDialog, commentsDialog] = useDisclosure();
    const [commentsDialogSelectedReport, setCommentsDialogSelectedReport] = useState<CompositeReport>();

    const { effectiveColumns } = useDataTableColumns({key, columns});

    const onReloadError = () => {
        showNotification({
            title: 'Nie udało się pobrać danych',
            message: 'Jeśli uważasz, że to błąd skontaktuj się z administratorem',
            color: 'red'
        })
    }

    const getSelectedElements = () => selectedRecords.map(record => record.id) as string[];

    const onApproveSelectedSuccess = (elements: string[]) => {
        showNotification({
            title: 'Zatwierdzono raportów',
            message: `Pomyślnie zatwierdzono ${elements.length} raportów`,
            color: 'green'
        })
    }

    const onApproveSelectedError = (elements: string[]) => {
        showNotification({
            title: `Nie udało się zatwierdzić ${elements.length} raportów`,
            message: 'Nie można zatwierdzić ponownie tego samego raportu. Jeśli uważasz, że to błąd skontaktuj się z administratorem.',
            color: 'red'
        });
    }

    const doApproveSelected = () => {
        const selectedElements = selectedRecords.map(r => r.id);
        const action: Action = { action: 'approve', selectedElements };

        startLoading();

        CompositeReportApi.performAction(action)
            .then(() => onApproveSelectedSuccess(selectedElements))
            .then(reload)
            .catch(() => onApproveSelectedError(selectedElements))
            .finally(stopLoading);
    }

    const approveSelected = () => {
        modals.openConfirmModal({
            title: "Zatwierdzanie raportów",
            children: `Czy na pewno chcesz zatwierdzić ${selectedRecords.length} raportów?`,
            labels: { confirm: 'Zatwierdź', cancel: 'Anuluj' },
            confirmProps: {color: 'green'},
            centered: true,
            onConfirm: doApproveSelected
        });
    }

    const doSendSelectedError = () => {
        showNotification({
            title: 'Nie udało się wysłać wiadomości',
            message: 'Jeśli problem będzie się powtarzał, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSendSelectedSuccess = () => {
        showNotification({
            title: 'Utworzono prototypy wysyłki',
            message: 'Możesz teraz edytować wiadomości i je wysłać',
            color: 'green'
        });
    }

    const doSendSelected = () => {
        startLoading();

        CompositeReportApi.performAction({selectedElements: getSelectedElements(), action: 'send'})
            .then(doSendSelectedSuccess)
            .catch(doSendSelectedError)
            .finally(stopLoading);
    }

    const sendSelected = () => {
        modals.openConfirmModal({
            title: "Czy zamierasz wysłać te raporty",
            children: `Spowoduje to utworzenie ${selectedRecords.length} prototypów wiadomości, które możesz edytować przed wysyłką.`,
            labels: { confirm: 'Tak', cancel: 'Nie' },
            centered: true,
            onConfirm: doSendSelected
        });
    }

    const onDeleteSelectedError = (elements: string[]) => {
        showNotification({
            title: 'Nie udało się usunąć raportów',
            message: `Nie usunięto ${elements.length} zaznaczonych raportów. Jeśli problem będzie się utrzymywał, skontaktuj się z administratorem.`,
            color: 'red'
        });
    }

    const onDeleteSelectedSuccess = (elements: string[]) => {
        showNotification({
            title: 'Usunięto zaznaczone raporty',
            message: `Pomyślnie usunięto ${elements.length} zaznaczonych raportów. Nie usunięto wygenerowanych plików.`,
            color: 'green'
        });
    }

    const resetSelected = () => {
        setSelectedRecords([]);
    }

    const doDeleteSelected = () => {
        startLoading();
        const selectedElements = selectedRecords.map(x => x.id);

        CompositeReportApi.performAction({selectedElements, action: 'delete'})
            .then(() => onDeleteSelectedSuccess(selectedElements))
            .then(resetSelected)
            .then(reload)
            .catch(() => onDeleteSelectedError(selectedElements))
            .finally(stopLoading);
    }

    const deleteSelected = () => {
        modals.openConfirmModal({
            title: "Usuwanie zaznaczonych",
            children: `Czy na pewno chcesz usunąć ${selectedRecords.length} zaznaczonych raportów. Nie spowoduje to usunięcia oryginalnych plików.`,
            labels: { confirm: 'Usuń', cancel: 'Anuluj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: doDeleteSelected
        });
    }

    const startLoading = () => { setLoading(true) };

    const stopLoading = () => { setLoading(false) };

    const reload = () => {
        startLoading();

        CompositeReportApi.search(searchQuery)
            .then(setPage)
            .catch(onReloadError)
            .finally(stopLoading);
    }

    const setPageNumber = (newPageNum: number) => {
        setSearchQuery({ ...searchQuery, page: newPageNum });
    }

    const setLimit = (newLimit: number) => {
        setSearchQuery({...searchQuery, limit: newLimit});
    }

    const doDeleteReportSuccess = () => {
        showNotification({
            title: `Usunięto raport`,
            message: 'Raport został poprawnie usunięty.',
            color: 'green'
        });
    }

    const doDeleteReportError = () => {
        showNotification({
            title: `Nie udało się usunąć raportu`,
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doDeleteReport = (report: CompositeReport) => {
        const action = { action: 'delete', selectedElements: [report.id]} as Action;

        startLoading();

        CompositeReportApi.performAction(action)
            .then(doDeleteReportSuccess)
            .then(reload)
            .catch(doDeleteReportError)
            .finally(stopLoading);
    }

    const doApproveReportSuccess = () => {
        showNotification({
            title: `Zatwierdzono raport`,
            message: 'Raport został poprawnie zatwierdzony.',
            color: 'green'
        });
    }

    const doApproveReportError = () => {
        showNotification({
            title: `Nie udało się zatwierdzić raportu`,
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doApproveReport = (report: CompositeReport) => {
        const action = { action: 'approve', selectedElements: [report.id]} as Action;

        startLoading();

        CompositeReportApi.performAction(action)
            .then(doApproveReportSuccess)
            .then(reload)
            .catch(doApproveReportError)
            .finally(stopLoading);
    }

    const deleteReport = (report: CompositeReport) => {
        modals.openConfirmModal({
            title: "Usuwanie raportu",
            children: (<>
                Czy na pewno chcesz usunąć ten raport?<br></br>
                Nie spowoduje to usunięcia żadnych plików z serwera.
            </>),
            labels: { confirm: `Tak, usuń`, cancel: 'Nie, nie usuwaj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: () => doDeleteReport(report)
        });
    }

    const approveReport = (report: CompositeReport) => {
        modals.openConfirmModal({
            title: "Zatwierdzanie raportu",
            children: `Czy na pewno chcesz zatwierdzić ${report.title ?? '<Raport bez tytułu>'}?`,
            labels: { confirm: `Zatwierdź`, cancel: 'Nie zatwierdzaj' },
            confirmProps: {color: 'green'},
            centered: true,
            onConfirm: () => doApproveReport(report)
        });
    }

    const doSendReportSuccess = () => {
        showNotification({
            title: `Utworzono prototyp wiadomości`,
            message: 'Możesz teraz edytować tą wiadomość, a później ją wysłać',
            color: 'green'
        });
    }

    const doSendReportError = () => {
        showNotification({
            title: `Nie udało się wysłać raportu`,
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red'
        });
    }

    const doSendReport = (report: CompositeReport) => {
        const action = { action: 'send', selectedElements: [report.id]} as Action;

        startLoading();

        CompositeReportApi.performAction(action)
            .then(doSendReportSuccess)
            .then(reload)
            .catch(doSendReportError)
            .finally(stopLoading);
    }

    const sendReport = (report: CompositeReport) => {
        modals.openConfirmModal({
            title: "Wysyłanie raportu",
            children: (<>
                Czy na pewno chcesz wysłać {report.title ?? '<Raport bez tytułu>'}?<br/>
                Spowoduje to utworzenie prototypu wiadomości, którą można później edytować
            </>),
            labels: { confirm: `Wyślij`, cancel: 'Nie wysyłaj' },
            confirmProps: {color: 'green'},
            centered: true,
            onConfirm: () => doSendReport(report)
        });
    }

    const onSortStatusChange = (newSortStatus: DataTableSortStatus<CompositeReport>) => {
        setSortStatus(newSortStatus);
        setSearchQuery({...searchQuery, sortBy: newSortStatus.columnAccessor, sortDir: newSortStatus.direction});
    }

    const doEditSimpleData = (cr: CompositeReport) => {
        setEditSimpleDataSelectedReport(cr);
        editSimpleDataDialog.open();
    }

    const doEditComments = (cr: CompositeReport) => {
        setCommentsDialogSelectedReport(cr);
        commentsDialog.open();
    }

    const selectApproved = () => {
        modals.openConfirmModal({
            title: "Zaznacz zatwierdzone",
            children: `Spowoduje to zaznaczenie tylko zatwierdzonych raportów widocznych na stronie.`,
            labels: { confirm: `Zaznacz`, cancel: 'Anuluj' },
            confirmProps: {color: 'green'},
            centered: true,
            onConfirm: doSelectApproved
        });
    }

    const doSelectApprovedSuccess = () => {
        showNotification({
            title: 'Zmieniono zaznaczenie',
            message: 'Zaznaczone są tylko zatwierdzone raporty',
            color: 'green',
            icon: <IconCheck/>
        })
    }

    const doSelectApproved = () => {
        const approvedReports = page?.content?.filter(report => report?.approvedAt !== null && report?.approvedAt !== undefined);
        setSelectedRecords(approvedReports ?? []);
        doSelectApprovedSuccess();
    }

    const doArchiveSelectedSuccess = () => {
        showNotification({
            title: 'Przeniesiono do archiwum',
            message: 'Wybrane raporty zostały przeniesione do archiwum',
            color: 'green',
            icon: <IconCheck/>
        })
    }

    const doArchiveSelectedError = ( ) => {
        showNotification({
            title: 'Nie udało się przenieść do archiwum',
            message: 'Jeśli problem nie ustąpi, proszę skontaktować się z administratorem',
            color: 'red',
            icon: <IconX/>
        });
    }

    const doArchiveSelected = () => {
        const a: Action = { action: 'archive', selectedElements: getSelectedElements() };

        startLoading();

        CompositeReportApi.performAction(a)
            .then(doArchiveSelectedSuccess)
            .then(reload)
            .then(resetSelected)
            .catch(doArchiveSelectedError)
            .finally(stopLoading);
    }

    const archiveSelected = () => {
        modals.openConfirmModal({
            title: "Zarchiwizuj zatwierdzone",
            children: `Spowoduje to przeniesienie raportów do archiwum, tej operacji nie można cofnąć.`,
            labels: { confirm: `Przenieś do archiwum`, cancel: 'Anuluj' },
            confirmProps: {color: 'red'},
            centered: true,
            onConfirm: doArchiveSelected
        });
    }

    useEffect(reload, [searchQuery]);

    return (
        <>
            <PageHeader
                title="Raporty miesięczne"
                url="/composite/report"
                action={<CompositeReportPageMenu
                    archiveSelected={archiveSelected}
                    generateForAllDevices={generateForAllDevicesDialog.open}
                    deleteSelected={deleteSelected}
                    approveSelected={approveSelected}
                    sendSelected={sendSelected}
                    doFilter={() => setFiltersVisible(!isFiltersVisible)}
                    selectApproved={selectApproved}
                />}
            />

            <PageContent>
                <CompositeReportFilter
                    isOpen={isFiltersVisible}
                    searchQuery={searchQuery}
                    doUpdate={setSearchQuery}
                />

                <DataTable
                    mt="xl"
                    striped
                    height="60vh"
                    withColumnBorders
                    styles={{ header: { background: 'white' } }}
                    highlightOnHover
                    loaderBackgroundBlur={1}
                    backgroundColor="transparent"
                    noRecordsText="Pusto..."
                    fetching={loading}
                    columns={effectiveColumns}
                    records={page?.content}
                    selectedRecords={selectedRecords}
                    onSelectedRecordsChange={setSelectedRecords}
                    minHeight={150}
                    style={{marginBottom: '50px'}}
                    storeColumnsKey={key}
                    sortStatus={sortStatus}
                    onSortStatusChange={onSortStatusChange}
                    onRowContextMenu={(data) => {
                        showContextMenu([
                            {
                                key: 'details',
                                title: 'Wyświetl raport',
                                icon: <IconEye size={16}/>,
                                onClick: () => navigate(`/composite/report/excel-details/${data.record.id}`)
                            },

                            {
                                key: 'edit-simple-cr-data-ctx-menu-item',
                                title: 'Edytuj dane',
                                icon: <IconEdit size={16}/>,
                                onClick: () => doEditSimpleData(data.record)
                            },

                            {
                                key: 'edit-comments-cr-data-ctx-menu-item',
                                title: 'Komentarze',
                                icon: <IconReport size={16}/>,
                                onClick: () => doEditComments(data.record)
                            },

                            {
                                key: 'composite-report-ctx-menu-approve-report',
                                title: 'Zatwierdź raport',
                                icon: <IconCheck size={16}/>,
                                onClick: () => approveReport(data.record)
                            },

                            {
                                key: 'composite-report-ctx-menu-send-report',
                                title: 'Wyślij raport',
                                icon: <IconSend size={16}/>,
                                onClick: () => sendReport(data.record)
                            },

                            {
                                key: 'composite-report-ctx-menu-delete-report',
                                title: 'Usuń raport',
                                color: 'red',
                                icon: <IconTrash size={16}/>,
                                onClick: () => deleteReport(data.record)
                            }
                        ])(data.event)
                    }}
                />

                <PaginationFooter
                    page={page}
                    setLimit={setLimit}
                    limit={searchQuery.limit}
                    setPageNumber={setPageNumber}
                    pageNumber={searchQuery.page}
                />
            </PageContent>

            <CompositeReportFilterDialog
                searchQuery={searchQuery}
                doUpdate={setSearchQuery}
                isOpen={isFilterDialogOpen}
                onClose={filterDialog.close}
            />

            <GenerateForAllDevicesDialog
                isOpen={isGenerateForAllDevicesOpen}
                justClose={generateForAllDevicesDialog.close}
                closeAndUpdate={() => { generateForAllDevicesDialog.close(); reload(); }}
            />

            <EditSimpleDataDialog
                isOpen={isEditSimpleDataDialogOpen}
                justClose={editSimpleDataDialog.close}
                closeAndUpdate={() => { reload(); editSimpleDataDialog.close(); }}
                compositeReport={editSimpleDataSelectedReport}
            />

            <NiceCommentsDialog
                isOpen={isCommentsDialog}
                justClose={commentsDialog.close}
                closeAndUpdate={() => { commentsDialog.close(); reload(); }}
                compositeReport={commentsDialogSelectedReport}
            />
        </>
    );
}

export default CompositeReportPage;
